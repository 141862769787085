<template>
  <v-card
    width="100%"
    class="loan-progress-card"
  >
    <v-card-title class="summary">
      <v-img
        :src="layouts.data.portfolioIcon"
        max-height="30"
        max-width="30"
      />
      <span class="loan-no ml-1">{{ layouts.data.loanNo }}</span>
      <v-spacer></v-spacer>
      <span class="loan-status-dot mr-1"></span>
      <span class="loan-status">In Progress</span>
    </v-card-title>
    <v-card-text class="content">
      <div class="field-container">
        <div class="field-label">Requested Amount</div>
        <div class="field-value">{{ assist.currency.format(layouts.data.requestAmount) }}</div>
      </div>
      <div class="progress-container">
        <v-progress-linear
          buffer-value="100"
          :color=layouts.settings.color.step1
          striped
          value="100">
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color=layouts.settings.color.step2
          striped
          value="100">
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color=layouts.settings.color.step3
          striped
          value="100">
        </v-progress-linear>
      </div>
      <div class="step-container">
        <template v-for="(item, index) in layouts.data.progressArray">
          <v-card
            :key="index"
            flat
            color="transparent"
            class="step"
          >
            <v-card-text>
              <v-sheet class="step-icon">
                <v-avatar v-if="item.status === 'finished'" size="14" color="var(--theme_primary)">
                  <v-icon
                    color="white"
                  >done
                  </v-icon>
                </v-avatar>
                <v-avatar v-else-if="item.status === 'in progress'" size="14" color="var(--theme_secondary)">
                  <v-icon
                    color="white"
                    style="animation-duration: 5s;"
                  >
                    fa-solid fa-hourglass-start fa-spin
                  </v-icon>
                </v-avatar>
                <v-avatar v-else size="14" color="#989898">
                  <v-icon
                    color="white"
                  >more_horiz
                  </v-icon>
                </v-avatar>
              </v-sheet>
              <div
                class="step-name"
                :style="item.status === 'in progress' ? 'color: var(--theme_secondary);' : item.status === 'unfinished' ? 'color: #989898;' : ''"
              >
                {{ item.text }}
              </div>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Currency, Tool, DataType } from '@/assets/js/util'

export default {
  name: 'WapLoanProgressCard',
  props: {
    loanInfo: {
      type: Object,
      default: () => {}
    },
    height: {
      type: [String, Number],
      default: 150
    }
  },
  data: () => ({
    assist: {
      currency: new Currency(),
      tool: new Tool()
    },
    layouts: {
      data: {
        portfolioIcon: '',
        loanNo: '',
        requestAmount: null,
        progressArray: [
          {
            text: 'Application Submitted',
            status: 'finished'
          },
          {
            text: 'Application Approved',
            status: 'finished'
          },
          {
            text: 'Funding In Progress',
            status: 'in progress'
          },
          {
            text: 'Funding Completed',
            status: 'unfinished'
          }
        ]
      },
      settings: {
        color: {
          step1: '#989898',
          step2: '#989898',
          step3: '#989898'
        }
      }
    },
    progressTimer: null
  }),
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolioIcon = val.iconUrl
        }
      },
      immediate: true,
      deep: true
    },
    loanInfo: {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.loanNo = val.loanNo
          this.layouts.data.requestAmount = val.totalPrincipal
          this.judgeLoanStatus()
        }
      }
    }
  },
  mounted () {
    this.layouts.data.loanNo = this.loanInfo.loanNo
    this.layouts.data.requestAmount = this.loanInfo.totalPrincipal
    if (this.assist.tool.isNotEmpty(this.loanInfo)) this.judgeLoanStatus()
  },
  methods: {
    onClickUpdate () {
      this.$router.push('/disbursement/choose-loan-amount')
    },
    judgeLoanStatus () {
      const Originating = DataType.LoanStatusEnum.ORIGINATING.value
      const Approved = DataType.LoanStatusEnum.APPROVED.value
      const Pending = DataType.LoanStatusEnum.PENDING.value
      const Disbursed = DataType.LoanStatusEnum.DISBURSED.value
      const availableStatus = [Originating, Approved, Pending, Disbursed]
      if (availableStatus.indexOf(this.loanInfo.loanStatus) === -1) {
        return
      }

      if (this.loanInfo.loanStatus === Approved || this.loanInfo.loanStatus === Pending) {
        this.layouts.data.progressArray[0].status = 'finished'
        this.layouts.data.progressArray[1].status = 'finished'
        this.layouts.data.progressArray[2].status = 'in progress'
        this.layouts.data.progressArray[3].status = 'unfinished'
        this.layouts.settings.color.step1 = 'var(--theme_primary)'
        this.layouts.settings.color.step2 = 'var(--theme_secondary)'
        this.layouts.settings.color.step3 = '#989898'
        return
      } else if (this.loanInfo.loanStatus === Disbursed) {
        this.layouts.data.progressArray[0].status = 'finished'
        this.layouts.data.progressArray[1].status = 'finished'
        this.layouts.data.progressArray[2].status = 'finished'
        this.layouts.data.progressArray[3].status = 'in progress'
        this.layouts.settings.color.step1 = 'var(--theme_primary)'
        this.layouts.settings.color.step2 = 'var(--theme_primary)'
        this.layouts.settings.color.step3 = 'var(--theme_secondary)'
        return
      }

      switch (this.loanInfo.subStatusList[0]) {
        case DataType.LoanSubStatusEnum.CUSTOMER_REVIEW.value:
        case DataType.LoanSubStatusEnum.NON_VOICE_AGENT_REVIEW.value:
        case DataType.LoanSubStatusEnum.AGENT_REVIEW.value:
          this.layouts.data.progressArray[0].status = 'in progress'
          this.layouts.data.progressArray[1].status = 'unfinished'
          this.layouts.data.progressArray[2].status = 'unfinished'
          this.layouts.data.progressArray[3].status = 'unfinished'
          this.layouts.settings.color.step1 = '#989898'
          this.layouts.settings.color.step2 = '#989898'
          this.layouts.settings.color.step3 = '#989898'
          break
        case DataType.LoanSubStatusEnum.UNDERWRITER_REVIEW.value:
        case DataType.LoanSubStatusEnum.AUTO_VALIDATION.value:
        case DataType.LoanSubStatusEnum.TRIBE_REVIEW.value:
          this.layouts.data.progressArray[0].status = 'finished'
          this.layouts.data.progressArray[1].status = 'in progress'
          this.layouts.data.progressArray[2].status = 'unfinished'
          this.layouts.data.progressArray[3].status = 'unfinished'
          this.layouts.settings.color.step1 = 'var(--theme_secondary)'
          this.layouts.settings.color.step2 = '#989898'
          this.layouts.settings.color.step3 = '#989898'
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.loan-progress-card
  & .content
    position: relative
    height: 130px

  & .loan-no
    font-size: 20px

  & .loan-status-dot
    width: 12px
    height: 12px
    border-radius: 20%
    background: #33dc3f

  & .loan-status
    font-weight: 700

  & .field-container
    & .field-label
      color: rgba(2, 2, 2, .4)
      font-size: 12px
      line-height: 1

    & .field-value
      color: rgba(2, 2, 2, .8)
      font-size: 30px
      font-weight: 700
      line-height: 1.5

  & .progress-container
    display: flex
    justify-content: space-between
    position: absolute
    top: 77px
    width: calc(100% - 32px)
    padding: 0 42px

  & .step-container
    display: flex
    justify-content: space-between
    position: absolute
    width: calc(100% - 32px)
    padding: 0 8px

    & .step
      text-align: center
      min-width: 50px

      & .step-icon
        display: flex
        justify-content: center
        align-items: flex-start
        height: 30px

        & .v-icon
          font-size: 10px

      & .step-name
        display: flex
        position: absolute
        left: 50%
        width: 60px
        -webkit-transform: translate(-50%, -50%)
        -moz-transform: translate(-50%, -50%)
        font-size: 10px
        line-height: 1

  & .update-btn
    color: var(--theme_secondary)
.loan-progress-card + .loan-progress-card
    margin-top: 20px
</style>
