<template>
  <v-card
    tile
    width="100%"
    class="loan-summary-card"
  >
    <v-card tile flat class="pa-0" @click="onClickLoanSummaryCard">
    <v-card-title class="summary">
      <v-img
        :src="layouts.data.portfolioIcon"
        max-height="30"
        max-width="30"
      />
      <span class="ml-1 loan-no">{{ layouts.data.repayment.loanNo }}</span>
      <v-spacer></v-spacer>
      <span class="loan-status-dot mr-1"></span>
      <span class="loan-status">Processing</span>
    </v-card-title>
    <v-card-text class="content">
      <v-layout class="field-container">
        <v-flex lg6 class="field">
          <div class="field-label">Total Principal</div>
          <div class="field-value">{{ assist.currency.format(layouts.data.repayment.totalPrincipal) }}</div>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex lg6 class="field right">
          <div class="field-label">Unpaid Principal</div>
          <div class="field-value">{{ assist.currency.format(layouts.data.repayment.unpaidPrincipal) }}</div>
        </v-flex>
      </v-layout>
      <div class="progress-container">
        <v-progress-linear
          buffer-value="100"
          :color=layouts.settings.color.step1
          striped
          :value=layouts.settings.value.step1>
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color=layouts.settings.color.step2
          striped
          :value=layouts.settings.value.step2>
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color=layouts.settings.color.step3
          striped
          :value=layouts.settings.value.step3>
        </v-progress-linear>
      </div>
      <div class="installment-container">
        <template v-for="(item, index) in layouts.settings.progressArray">
          <v-card
            :key="index"
            flat
            color="transparent"
            class="installment"
          >
            <v-card-text v-if="item.isShow">
              <div class="installment-summary">
                <div
                  class="installment-date"
                >{{ item.date }}</div>
                <div
                  class="installment-amount"
                >{{ assist.currency.format(item.repayment) }}</div>
              </div>
              <div class="installment-icon">
                <v-icon
                  v-if="item.status === 'finished'"
                  :color=layouts.settings.point.step1
                >fa-circle</v-icon>
                <v-icon
                  v-else-if="item.status === 'in progress'"
                  :color=layouts.settings.point.step2
                >fa-circle</v-icon>
                <v-icon
                  v-else
                  :color=layouts.settings.point.step3
                >fa-circle</v-icon>
              </div>
              <div
                class="installment-no"
                :style="item.status === 'in progress' ? 'color: var(--theme_secondary);' : item.status === 'unfinished' ? 'color: #989898;' : ''"
              >
                #{{ item.period }}
              </div>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </v-card-text>
    <v-card-text class="footer">
      <v-layout>
        <v-flex lg6>
          <div class="field">
            <span class="field-label">First Due Date: </span>
            <span class="field-value">{{ layouts.data.repayment.firstDebitDate }}</span>
          </div>
        </v-flex>
        <v-flex lg6>
          <div class="field right">
            <span class="field-label">Maturity Date: </span>
            <span class="field-value">{{ layouts.data.repayment.lastDebitDate }}</span>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { Currency, Tool, DataType } from '@/assets/js/util'

export default {
  name: 'LoanSummaryCard',
  components: {},
  props: {
    loanInfo: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    assist: {
      currency: new Currency(),
      tool: new Tool()
    },
    layouts: {
      data: {
        portfolioIcon: '',
        repayment: {
          totalPrincipal: null,
          firstDebitDate: '',
          lastDebitDate: '',
          loanNo: '',
          loanId: '',
          unpaidPrincipal: '',
          nextDebitCount: null,
          nextAmount: null,
          nextDebitDate: null,
          debitCount: null,
          installments: []
        }
      },
      settings: {
        progressArray: [
          {
            period: 1,
            date: '',
            repayment: '',
            isShow: true,
            status: 'finished'
          },
          {
            period: null,
            date: '',
            repayment: '',
            isShow: false,
            status: 'in progress'
          },
          {
            period: null,
            date: '',
            repayment: '',
            isShow: true,
            status: 'in progress'
          },
          {
            period: null,
            date: '',
            repayment: '',
            isShow: true,
            status: 'unfinished'
          }
        ],
        color: {
          step1: '#989898',
          step2: '#989898',
          step3: '#989898'
        },
        value: {
          step1: 100,
          step2: 100,
          step3: 0
        },
        point: {
          step1: '#989898',
          step2: '#989898',
          step3: '#989898'
        }
      }
    }
  }),
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolioIcon = val.iconUrl
        }
      },
      immediate: true,
      deep: true
    },
    loanInfo: {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.repayment = val
          this.initProgressStatus()
        }
      }
    }
  },
  mounted () {
    this.layouts.data.portfolioIcon = this.store.getters.getPortfolioJson.iconUrl
    this.layouts.data.repayment = this.loanInfo
    this.initProgressStatus()
  },
  methods: {
    onClickLoanSummaryCard () {
      this.$store.commit('setRepaymentLoanInfo', this.layouts.data.repayment)
      this.$router.push('/repayment/loan-details')
    },
    initProgressStatus () {
      this.layouts.data.repayment.installments = this.loanInfo.installments.filter((i) => i.transactionType === Number(DataType.TransactionTypeEnum.DEBIT.value))
      this.layouts.data.repayment.firstDebitDate = this.loanInfo.installments[0]?.transactionDate
      this.layouts.data.repayment.firstDebitAmount = this.loanInfo.installments[0]?.principal
      this.layouts.data.repayment.lastDebitDate = this.loanInfo.installments[this.loanInfo.installments.length - 1]?.transactionDate
      this.layouts.data.repayment.lastDebitAmount = this.loanInfo.installments[this.loanInfo.installments.length - 1]?.principal
      const result = this.layouts.data.repayment

      for (const item of this.layouts.settings.progressArray) {
        if (item.status === 'finished') {
          item.date = result.firstDebitDate
          item.repayment = result.firstDebitAmount
        }
        if (item.status === 'unfinished') {
          item.date = result.lastDebitDate
          item.period = result.debitCount
          item.repayment = result.lastDebitAmount
          if (result.nextDebitCount === 1 || this.assist.tool.isEmpty(result.nextDebitCount)) {
            this.layouts.settings.color.step1 = '#9A9A9A'
            this.layouts.settings.color.step2 = '#9A9A9A'
            this.layouts.settings.color.step3 = '#9A9A9A'
            this.layouts.settings.value.step1 = 100
            this.layouts.settings.value.step2 = 100
            this.layouts.settings.value.step3 = 100
            this.layouts.settings.point.step3 = '#9A9A9A'
          }
          if (result.nextDebitCount === result.debitCount) {
            this.layouts.settings.color.step1 = 'var(--theme_primary)'
            this.layouts.settings.color.step2 = 'var(--theme_primary)'
            this.layouts.settings.color.step3 = 'var(--theme_secondary)'
            this.layouts.settings.value.step1 = 100
            this.layouts.settings.value.step2 = 100
            this.layouts.settings.value.step3 = 100
            this.layouts.settings.point.step1 = 'var(--theme_primary)'
            this.layouts.settings.point.step3 = 'var(--theme_secondary)'
          }
          if (this.assist.tool.isEmpty(result.nextDebitCount) || result.nextDebitCount === 0) {
            this.layouts.settings.color.step1 = 'var(--theme_primary)'
            this.layouts.settings.color.step2 = 'var(--theme_primary)'
            this.layouts.settings.color.step3 = 'var(--theme_primary)'
            this.layouts.settings.value.step1 = 100
            this.layouts.settings.value.step2 = 100
            this.layouts.settings.value.step3 = 100
            this.layouts.settings.point.step3 = 'var(--theme_primary)'
          }
        }
        if (item.status === 'in progress') {
          if (this.assist.tool.isEmpty(result.nextDebitCount) || result.nextDebitCount === result.debitCount) {
            item.isShow = false
          }
          if (result.nextDebitCount !== 1) {
            item.date = result.nextDebitDate
            item.period = result.nextDebitCount
            item.repayment = result.nextAmount
            this.layouts.settings.point.step1 = 'var(--theme_primary)'
            this.layouts.settings.point.step2 = 'var(--theme_secondary)'
            this.layouts.settings.color.step1 = 'var(--theme_primary)'
            this.layouts.settings.color.step2 = 'var(--theme_secondary)'
          } else {
            item.isShow = false
            this.layouts.settings.color.step1 = '#989898'
            this.layouts.settings.color.step2 = '#989898'
            this.layouts.settings.value.step1 = 0
            this.layouts.settings.value.step2 = 0
            this.layouts.settings.point.step1 = 'var(--theme_secondary)'
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .field
    & .field-label
      color: rgba(2, 2, 2, .4)
    & .field-value
      color: rgba(2, 2, 2, .8)
    &.right
      text-align: right
  .loan-summary-card
    & .content
      position: relative
      height: 110px
    & .footer
      font-size: 10px
      padding-top: 0
      padding-bottom: 8px
    & .actions
      padding: 0 8px 8px 8px
    & .loan-no
      font-size: 20px
    & .loan-status-dot
      width: 12px
      height: 12px
      border-radius: 20%
      background: #33dc3f
    & .loan-status
      font-weight: 700
    & .field-container
      display: flex
      justify-content: space-between
      & .field
        & .field-label
          font-size: 12px
          line-height: 1
        & .field-value
          font-size: 24px
          font-weight: 700
          line-height: 1.5
    & .progress-container
      display: flex
      justify-content: space-between
      position: absolute
      top: 82px
      width: calc(100% - 32px)
      padding: 0 24px
    & .installment-container
      display: flex
      justify-content: space-between
      position: absolute
      width: calc(100% - 32px)
      & .installment
        text-align: center
        min-width: 50px
        & .installment-summary
          position: absolute
          left: 50%
          -webkit-transform: translate(-50%, -50%)
          -moz-transform: translate(-50%, -50%)
          font-size: 10px
          line-height: 1
          white-space: nowrap
        & .installment-icon
          display: flex
          justify-content: center
          align-items: center
          height: 40px
          & .v-icon
            font-size: 12px
        & .installment-no
          display: flex
          position: absolute
          left: 50%
          -webkit-transform: translate(-50%, -50%)
          -moz-transform: translate(-50%, -50%)
          font-size: 10px
          line-height: 1
  .loan-summary-card + .loan-summary-card
    margin-top: 20px
</style>
