<template>
  <div>
    <v-sheet
      elevation="2"
      class="function-panel"
    >
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            link
            flat
            tile
            align="center"
            class="rounded-l-lg"
            @click="onClickDisbursement"
          >
            <v-card-text>
              <v-icon
                large
              >fa-hand-holding-usd
              </v-icon>
              <div>Disbursement</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-card
            link
            flat
            tile
            align="center"
            class="rounded-r-lg"
            @click="onClickRepayment"
          >
            <v-card-text>
              <v-icon
                large
              >fa-envelope-open-dollar
              </v-icon>
              <div>Repayment</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <div class="pa-4">
      <v-btn height="80" block @click="onClickAttach" class="primary-color f-s-16 f-w-700">
          <v-icon size="40">mdi-attachment</v-icon>Link Existing Loan
      </v-btn>
      <div class="card-container mt-4" v-if="displayCarousel">
        <vue-perfect-scrollbar class="scroll">
          <template v-for="(item, index) in cardItems" >
            <component :is="cardComponent" :loan-info="item" :key="index"></component>
          </template>
        </vue-perfect-scrollbar>
      </div>

      <v-alert
        v-else
        prominent
        type="warning"
        border="left"
        class="mt-15"
      >
        No linked loan.<br/>
        Please click above button to link a loan.
      </v-alert>
    </div>
    <wap-bottom-sheet
      v-model="layouts.dialog.attachLoanSheet"
      :persistent="true"
      title="Link Existing Loan"
      @cancel="layouts.dialog.attachLoanSheet = false"
      @confirm="onClickConfirm"
    >
      <div class="px-8">
        <v-text-field
          v-model="layouts.data.loanNo"
          v-mask="'XXX-XXX-XXXX'"
          label="Enter Loan #"
        ></v-text-field>

        <v-text-field
          v-model="layouts.data.bankAccount"
          label="Enter Bank Account #"
        ></v-text-field>
        <v-alert
          dense
          text
          border="left"
          color="var(--theme_primary)"
          class="f-s-12"
        >
          <span class="text-red">*</span> You can find the needed information from the loan agreement of your existing loan.
        </v-alert>
      </div>
    </wap-bottom-sheet>
  </div>
</template>

<script>
import WapLoanProgressCard from '@/components/home/WapLoanProgressCard'
import LoanSummaryCard from '@/components/home/LoanSummaryCard'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import { LoanApi } from '@/api'
import { DataType, Tool } from '@/assets/js/util'
import _ from 'lodash'

export default {
  name: 'Home',
  components: {
    WapLoanProgressCard,
    LoanSummaryCard,
    WapBottomSheet
  },
  data () {
    return {
      assist: {
        tool: new Tool()
      },
      layouts: {
        data: {
          loanNo: '',
          bankAccount: '',
          cardDisplayControl: 'Disbursement',
          progressCardArray: [],
          summaryCardArray: []
        },
        dialog: {
          attachLoanSheet: false
        }
      }
    }
  },
  computed: {
    cardItems () {
      return this.layouts.data.cardDisplayControl === 'Disbursement'
        ? this.layouts.data.progressCardArray
        : this.layouts.data.summaryCardArray
    },
    displayCarousel () {
      if (this.layouts.data.cardDisplayControl === 'Disbursement') {
        return this.layouts.data.progressCardArray.length !== 0
      } else if (this.layouts.data.cardDisplayControl === 'Repayment') {
        return this.layouts.data.summaryCardArray.length !== 0
      }
      return false
    },
    cardComponent () {
      return this.layouts.data.cardDisplayControl === 'Disbursement' ? 'wap-loan-progress-card' : 'loan-summary-card'
    }
  },
  watch: {
    'layouts.dialog.attachLoanSheet' (val) {
      if (!val) {
        this.layouts.data.loanNo = ''
        this.layouts.data.bankAccount = ''
      }
    }
  },
  mounted () {
    this.getDisbursementLoan()
    this.getRepaymentLoan()
  },
  methods: {
    onClickAttach () {
      this.layouts.dialog.attachLoanSheet = true
    },
    onClickConfirm () {
      const request = {
        loanNo: this.layouts.data.loanNo,
        accountNo: this.layouts.data.bankAccount,
        portfolioId: this.$store.getters.getPortfolioId
      }
      LoanApi.attachLoan(request, () => {
        this.layouts.dialog.attachLoanSheet = false
        this.getDisbursementLoan()
        this.getRepaymentLoan()
      }, (failure) => {
        this.$store.commit('setPopupInformation', {
          message: failure.message
        })
      })
    },
    onClickRepayment () {
      this.layouts.data.cardDisplayControl = 'Repayment'
      this.getLoanCards()
    },
    onClickDisbursement () {
      this.getLoanCards()
      this.layouts.data.cardDisplayControl = 'Disbursement'
    },
    getLoanCards: _.throttle(
      function () {
        this.getDisbursementLoan()
        this.getRepaymentLoan()
      }, 5000, { trailing: false }),
    getDisbursementLoan () {
      LoanApi.listDisbursementLoan((res) => {
        if (this.assist.tool.isNotEmpty(res.attachLoanList)) {
          const Originating = DataType.LoanStatusEnum.ORIGINATING.value
          const Approved = DataType.LoanStatusEnum.APPROVED.value
          const Pending = DataType.LoanStatusEnum.PENDING.value
          const Disbursed = DataType.LoanStatusEnum.DISBURSED.value
          const availableStatus = [Originating, Approved, Pending, Disbursed]
          this.layouts.data.progressCardArray = res.attachLoanList.filter((i) => availableStatus.includes(i.loanStatus))
        }
      })
    },
    getRepaymentLoan () {
      LoanApi.listRepaymentLoan((res) => {
        if (this.assist.tool.isNotEmpty(res.attachLoanList)) {
          this.layouts.data.summaryCardArray = res.attachLoanList
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.function-panel
  border-radius: 8px
  margin: -45px 16px 0 16px

.card-container
  width: 100%
  height: 400px

.scroll
  height: 100%
  width: 100%
  padding: 0 5px
.primary-color
  color: var(--theme_primary) !important
</style>
